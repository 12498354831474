@use '~@lululemon/ecom-pattern-library/lib/styles/includes.scss' as *;

.forgotLink span {
  margin-left: 0 !important ;
}

.loadingIndicator {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 50;
  background-color: transparent;
  backdrop-filter: blur(1px);
}
